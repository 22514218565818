export class MobileMenu {
    protected activator: HTMLElement;
    constructor() {
        this.activator = document.querySelector('.nav__m')!;
    }

    async new() {
        window.observer.observe(this.activator, 'click', async ev => {
            if (!ev.element)
                return console.error('Mobile menu selector unknown (FIJhsdf897dsfg)');
            
            const NAV = ev.element.nextElementSibling;
            if (!NAV)
                return console.error('Missing navigation (89073947659345FIUHJFG)');

            const DROPDOWN = NAV.querySelector('ul');
            if (!DROPDOWN)
                return console.error('Missing menu (982346759JGVuirfdsg)');

            ev.element.classList.toggle('active');
            DROPDOWN.classList.toggle('active');
        });
    }
}
