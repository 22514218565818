import { Lazyload } from "./components/lazyloading/lazyload.module";
import { MobileMenu } from "./components/menu/mobile.menu";
import { Observer } from "./tools/observer";

class HomePage {
    async new() {
        window.observer = new Observer();

        let lazyloading = new Lazyload();
        lazyloading.load_io();

        let mobileMenu = new MobileMenu();
        mobileMenu.new();
    }
}

window.addEventListener('load', async () => new HomePage().new());
