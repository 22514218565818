/**
 * Images lazyloading
 */
export class Lazyload {
    protected custom_event: Event;
    constructor() {
        this.custom_event = new CustomEvent('__LLOO__', {cancelable: true});
    }

    /**
     * Load images when in viewport using intersection observer API
     * 
     * @param sidebar_scroll
     * @param sidebar_selector
     * 
     * @returns void
     */
    async load_io(sidebar_scroll: boolean = false, sidebar_selector: string | boolean = false): Promise<void> {
        let lazy_images: NodeListOf<HTMLImageElement> = document.querySelectorAll('.js-lazy:not(.b-loaded)');
        if (lazy_images) {
            if ('IntersectionObserver' in window) {
                let io = new IntersectionObserver((e, o) => {
                    e.forEach(i => {
                        if (i.isIntersecting) {
                            let l_img: HTMLImageElement = <HTMLImageElement>i.target;
                            l_img.src = l_img.dataset.src!;
                            l_img.classList.add('b-loaded');

                            let figure: HTMLElement | null = l_img.closest('figure');
                            if (figure) figure.classList.add('img-is-loaded');

                            delete l_img.dataset.src;
                            io.unobserve(l_img);
                            l_img.dispatchEvent(this.custom_event);
                        }
                    });
                });

                lazy_images.forEach(image => {
                    io.observe(image);
                });
            } else { // io not available in the browser, use fallback func
                await this._load(sidebar_scroll, sidebar_selector);
            }
        }
    }

    /**
     * Load images when in viewport
     * 
     * @param sidebar_scroll
     * @param sidebar_selector
     * 
     * @returns void
     */
    async _load(sidebar_scroll: boolean = false, sidebar_selector: string | boolean = false): Promise<void> {
        let lazy_images: NodeListOf<HTMLImageElement> = document.querySelectorAll('.js-lazy:not(.b-loaded)');
        if (lazy_images) {
            let trigger = () => {
                lazy_images.forEach(async (el) => {     
                    if (el.classList.contains('b-loaded')) return;
                    else {
                        let bcr: DOMRect = el.getBoundingClientRect();
                        if (bcr.top <= window.innerHeight) {
                            const img_load_promise = new Promise(() => {
                                let img: HTMLImageElement = new Image();
                                img.src = el.dataset.src!;
                                img.onload = () => {
                                    el.src = img.src;
                                    el.classList.remove('js-lazy');

                                    let figure: HTMLElement | null = el.closest('figure');
                                    if (figure) figure.classList.add('img-is-loaded');
                                    
                                    delete el.dataset.src;
                                }
                            });

                            await img_load_promise;
                        }
                    }
                });
            }

            trigger();

            if (sidebar_scroll === true && typeof sidebar_selector === 'string') {
                let sidebar: HTMLElement = document.querySelector(sidebar_selector)!;
                sidebar.addEventListener('scroll', trigger);
                window.addEventListener('resize', trigger);
            } else {
                window.addEventListener('scroll', trigger);
                window.addEventListener('resize', trigger);
            }
        }
    }
}